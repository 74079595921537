<template>
  <Card v-show="!isLoading">
    <template #content>
      <div class="flex flex-col items-center" v-if="!isSubscribed">
        <div class="uppercase pb-6 text-sm">Payment Method</div>
        <form ref="payment-form">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
          <!-- <div ref="error"></div> -->
          <Button
            :loading="isSubmitting"
            loading-icon="pi pi-spin pi-spinner"
            ref="submit"
            class="flex items-center btn--primary bg-primary font-bold mt-4"
            :label="`Start free two-week trial`"
            @click="handleSubmit()"
          ></Button>
          <Button class="text-sm mt-4 w-full items-center" link @click="cancel" label="Cancel" />
        </form>
      </div>
      <div class="flex flex-col items-center" v-if="isSubscribed">
        <i class="pi pi-check-circle text-8xl text-primary"></i>
        <div class="text-xl text-primary mt-4">Thank you for subscribing!</div>
      </div>
    </template>
  </Card>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { loadStripe } from '@stripe/stripe-js'
import Button from 'primevue/button'
import Card from 'primevue/card'
import { userApi } from '@/services/users/'
import { createSubscription, getProductsWithPrices } from '@/services/stripe/'
import { stripePublicKey } from '../services/stripe/api'

const stripe = ref()
const elements = ref()
const price = ref()
const submit = ref()
const isLoading = ref(false)
const isSubmitting = ref(false)
const emit = defineEmits(['cancel', 'subscribed'])
const cancel = () => emit('cancel')
const isSubscribed = ref(false)
const store = useStore()

onMounted(async () => {
  isLoading.value = true
  stripe.value = await loadStripe(stripePublicKey)

  try {
    // For now there is only one product and one price.
    // If we offer others, alter ui to use dropdown or other select
    price.value = await getProductsWithPrices()
  } catch (e) {
    console.log(e)
  }

  const appearance = {
    theme: 'stripe',
  }

  const options = {
    mode: 'subscription',
    amount: price.value,
    currency: 'usd',
    appearance: appearance,
    loader: 'always',
    paymentMethodCreation: 'manual',
  }

  elements.value = stripe.value.elements(options)
  const paymentElement = elements.value.create('payment', options)
  paymentElement.on('ready', function (event) {
    isLoading.value = false
  })

  paymentElement.mount('#payment-element')
})

const handleError = (e) => {
  // error.value.textContent = e.message
  isSubmitting.value = false
}

const handleSubmit = async (e, values) => {
  isSubmitting.value = true

  // Trigger form validation and wallet collection
  const { error: submitError } = await elements.value.submit()
  if (submitError) {
    handleError(submitError)
    return
  }

  // Create the PaymentMethod using the details collected by the Payment Element
  const { error, paymentMethod } = await stripe.value.createPaymentMethod({
    elements: elements.value,
    params: {
      billing_details: {},
    },
  })

  if (error) {
    console.log('error', error)
    // This point is only reached if there's an immediate error when
    // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
    handleError(error)
    return
  }

  try {
    // Now that we have a payment method, pass this to backend and create our new subscription
    await createSubscription(paymentMethod.id)
    isSubscribed.value = true

    setTimeout(async () => {
      const updatedUser = await userApi.retrieve(store.getters.user.id)
      await store.dispatch('setUser', { ...store.getters.user, ...updatedUser })
      emit('subscribed')
    }, 2000)
  } catch (e) {
    console.log(e)
  }

  isSubmitting.value = false
}
</script>
