import { prReviewRegex, stagingRegex } from '../../utils/urlRegex'
import axiosInstance from '../AxiosClient'

export const getProductsWithPrices = async (paymentMethodId) => {
  const res = await axiosInstance.get('/stripe/products/', { payment_method_id: paymentMethodId })
  return res.data['products'][0]['prices'][0]['unit_amount']
}

export const updatePaymentMethod = async (paymentMethodId) => {
  return await axiosInstance.post('/stripe/payment/', { payment_method_id: paymentMethodId })
}

export const createSubscription = async (paymentMethodId) => {
  return await axiosInstance.post('/stripe/subscription/', { payment_method_id: paymentMethodId })
}

export const cancelSubscription = async (paymentMethodId) => {
  return await axiosInstance.delete('/stripe/subscription/')
}

export const stripePublicKey = (() => {
  const location = window.location.hostname
  console.log('location', location)
  console.log('prtest', prReviewRegex.test(location))
  console.log('staging', stagingRegex.test(location))
  const testPublicKey =
    'pk_test_51ONgMlCE0t2hkdOSs3mj9fVl7sSTH5vQ0CAFd208lzucv903EmNImsDoX56zYKoKOIhO1Alad45XyzNEYt0owulb00ZopfPZ1p'
  if (prReviewRegex.test(location)) {
    return testPublicKey
  }
  if (stagingRegex.test(location)) {
    return testPublicKey
  }
  return 'pk_live_51ONgMlCE0t2hkdOSkxSupcI3ih9bsA3GBXxM4YungleKVEELKq44lYOhVSr5qitkoDWmHh6kEJhfQzGGSANNA7Es00k3Oh7Zf3'
})()
