import { createApp } from 'vue'
import PrimeVue from 'primevue/config'
import App from '../src/App.vue'
import router from './router'
import store from './store'
import Tailwind from './themes/tailwind'
import './main.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
  .use(store)
  .use(router)
  .use(VueReCaptcha, { siteKey: '6LeP8UsqAAAAALa8p1tdMa60EP_G9zrjPJ8f50Fj' })
  .use(PrimeVue, { unstyled: true, pt: Tailwind })
  .mount('#app')
